import get from "lodash/get";

// Bets for you
// Recommended competitions
// Leagues

// LV:
// Tev varētu interesēt
// Ieteiktās sacensības
// Līgas

// EE:
// Panused Sinule
// Soovitatud võistlused
// Liigad

// LT:
// Statymai Tau
// Rekomenduojami turnyrai
// Lygos

// Finnish:
// Vetokohteet sinulle
// Suositellut kohteet
// Sarjat

// Ставки для Вас
// Рекомендуемые соревнования
// Лиги

const TRANSLATIONS = {
  eng: {
    recommended_competitions: "Recommended competitions",
    leagues: "Leagues",
    upcoming: "upcoming",
    live: "live",
    go_to_all_live: "Go to all live events",
    go_to_all: "Go to all",
    OlyBoosts: "OlyBoosts",
  },
  rus: {
    recommended_competitions: "Рекомендуемые соревнования",
    leagues: "Лиги",
    upcoming: "Предстоящий",
    live: "лайв",
    go_to_all_live: "Перейти ко всем Лайв событиям",
    go_to_all: "ОТКРЫТЬ ВСЁ",
    OlyBoosts: "OlyBoosts",
  },
  fin: {
    recommended_competitions: "Suositellut kohteet",
    leagues: "Sarjat",
    go_to_all_live: "Siirry kaikkiin livetapahtumiin",
    OlyBoosts: "OlyBoosts",
  },
  gre: {
    recommended_competitions: "Προτεινόμενοι αγώνες",
    leagues: "Πρωταθλήματα",
    go_to_all_live: "Δείτε όλους τους ζωντανούς αγώνες",
    OlyBoosts: "OlyBoosts",
  },
  lav: {
    recommended_competitions: "Ieteiktās sacensības",
    leagues: "Līgas",
    go_to_all_live: "Go to all live events",
    go_to_all: "APSKATĪT VISU",
    OlyBoosts: "OlyBoosts",
  },
  lit: {
    recommended_competitions: "Turnyrai",
    leagues: "Lygos",
    upcoming: "upcoming",
    go_to_all_live: "Go to all live events",
    go_to_all: "ATIDARYTI VISUS",
    OlyBoosts: "Hot Bets",
  },
  est: {
    recommended_competitions: "Soovitatud võistlused",
    leagues: "Liigad",
    upcoming: "upcoming",
    go_to_all_live: "Go to all live events",
    go_to_all: "Näita kõiki",
    OlyBoosts: "OlyBoosts",
  },
};

export const T = (language, key) => {
  return get(TRANSLATIONS, [language, key], key);
};
