import queryString from "query-string";
import { normalize } from "normalizr";
import { SITE_ID } from "../store/socketEventHandlers";
import { schemas } from "../schemas";
import settings from "../settings";
import get from "lodash/get";

import { unsubscribeBulk as _unsubscribeBulk } from "../utils";

// const createEndpoint = ({baseEndpoint, queryParams}) => {
//   return `${baseEndpoint}/?${queryString.stringify(queryParams)}`
// }

function getHeaders(state) {
  const ret = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${settings.API_TOKEN}`,
  };

  return ret;
}

export const RESET_ERROR_MESSAGE = "RESET_ERROR_MESSAGE";

export const resetErrorMessage = () => ({
  type: RESET_ERROR_MESSAGE,
});

export const UPDATE_ERROR_MESSAGE = "UPDATE_ERROR_MESSAGE";

export const updateErrorMessage = (error) => (dispatch, getState) => {
  dispatch({ type: UPDATE_ERROR_MESSAGE, error });
};

export const ADD_MESSAGE = "ADD_MESSAGE";

export const addMessage = (msg) => (dispatch, getState) => {
  dispatch({
    type: ADD_MESSAGE,
    payload: normalize(msg, schemas.message),
  });
};

export const UPDATE_ITEM = "UPDATE_ITEM";
export const REPLACE_ITEM = "REPLACE_ITEM";
export const RESET_ITEMS = "RESET_ITEMS";

export const updateItem =
  ({ entityKey, id, data }) =>
  (dispatch, getState) => {
    return dispatch({
      type: UPDATE_ITEM,
      payload: data,
      meta: { id, key: entityKey },
    });
  };

export const replaceItem =
  ({ entityKey, id, data }) =>
  (dispatch, getState) => {
    return dispatch({
      type: REPLACE_ITEM,
      payload: data,
      meta: { id, key: entityKey },
    });
  };

export const resetItems =
  ({ entityKey }) =>
  (dispatch, getState) => {
    return dispatch({
      type: RESET_ITEMS,
      meta: { key: entityKey },
    });
  };

const countryCodes = {
  521: "FIN",
  56: "EST",
  313: "LTU",
  656: "LVA",
  112233: "FIN",
};

export const LIST_REQUEST = "LIST_REQUEST";
export const LIST_SUCCESS = "LIST_SUCCESS";
export const LIST_FAILURE = "LIST_FAILURE";

export const loadList =
  ({ url, meta }) =>
  (dispatch, getState) => {
    dispatch({ type: LIST_REQUEST, meta });

    fetch(url, { headers: getHeaders(getState()), type: "cors" }).then(
      (resp) => {
        resp.json().then((json) => {
          resp.ok
            ? dispatch({ type: LIST_SUCCESS, payload: json, meta })
            : dispatch({ type: LIST_FAILURE, payload: json, meta });
        });
      }
    );
  };

export const loadRecommendations = () => (dispatch, getState) => {
  const user = get(getState(), "entities.auth.user");
  if (!user || !user.ab_test_group) {
    return;
  }

  // const baseUrl = user.ab_test_group === 'test' ?
  //   `${settings.BASE_API_URL}api/bet_recommendations` :
  //   `${settings.BASE_API_URL}api/events/popular`;

  const baseUrl =
    user.id === "0"
      ? `${settings.BASE_API_URL}api/sports/events/popular`
      : `${settings.BASE_API_URL}api/sports/events/recommended`;

  const getQueryString =
    user.id === "0"
      ? {
          count: 150,
          filters: `status:in:not_started,live;user_country:eq:${countryCodes[SITE_ID]}`,
          // filters: `status:in:not_started,live`,
          // fields: `event_id,total_bets,league_id,status,league`,
          from_offset: "-3h",
          to_offset: "48h",
        }
      : {
          user: user.id,
          count: 100,
          filters: `status:in:not_started,live`,
          fields: "event_id,confidence,league_id,status,league",
          from_offset: "-3h",
          to_offset: "48h",
        };

  const recommendationsQueryString = queryString.stringify(getQueryString);

  dispatch(
    loadList({
      url: `${baseUrl}/?${recommendationsQueryString}`,
      meta: {
        reqId: "recommendations",
        key: "current",
      },
    })
  );
};

export const LOAD_USER_INFO_SUCCESS = "LOAD_USER_INFO_SUCCESS";
export const LOAD_USER_INFO_FAILURE = "LOAD_USER_INFO_FAILURE";

export const loadUserInfo = (userId) => (dispatch, getState) => {
  const baseUrl = `${settings.BASE_API_URL}api/ab_test_users`;
  const qs = queryString.stringify({ user: userId || "" });
  const url = `${baseUrl}/?${qs}`;

  return fetch(url, { headers: getHeaders(getState()), type: "cors" }).then(
    (resp) => {
      if (resp.ok) {
        return resp.json().then((json) => {
          updateItem({
            entityKey: "auth",
            id: "user",
            data: json,
          })(dispatch, getState);
        });
      } else {
        updateItem({
          entityKey: "auth",
          id: "user",
          data: { id: userId, ab_test_group: "control" },
          // data: {id: 870226, ab_test_group: 'test'}
        })(dispatch, getState);
      }
    }
  );
};

export const UNSUBSCRIBE_BULK = "UNSUBSCRIBE_BULK";
export const unsubscribeBulk = (ws) => (dispatch, getState) => {
  const subids = Object.keys(get(getState(), "entities.messages", {})).filter(
    (s) => s !== "sid"
  );
  if (subids.length > 0) {
    _unsubscribeBulk({ ws, subids });

    dispatch({ type: UNSUBSCRIBE_BULK });
  }
};
