import { schema } from "normalizr";

const leagueSchema = new schema.Entity("leagues", {}, { idAttribute: "id" });
const eventSchema = new schema.Entity("events", {}, { idAttribute: "id" });
const messageSchema = new schema.Entity(
  "messages",
  {},
  { idAttribute: "subid" }
);
const recommendationSchema = new schema.Entity(
  "recommendations",
  {},
  { idAttribute: "event_id" }
);

export const schemas = {
  message: messageSchema,
  leagues: leagueSchema,
  events: eventSchema,
  recommendations: recommendationSchema,
};
