import { connect } from "react-redux";
import Events from "../Events";
import Leagues from "../Leagues";
import { isMobile, getEvents, getLeagues } from "../../utils";
import isEmpty from "lodash/isEmpty";
import Loader from "../Loader";
import componentsSettings from "../../components/componentsSettings";

import "./App.css";
import "./Animations.css";

// window.postMessage({type: "selectedLanguage", value: "eng"})

const theme = componentsSettings.components.loader.enabledTheme;

const App = (props) => {
  const isLoading = isMobile() ? isEmpty(props.events) : isEmpty(props.leagues);
  return isMobile() ? (
    <>{isLoading ? <Loader /> : <Events />}</>
  ) : (
    <>
      {isLoading ? (
        <div className="loading-desktop">
          <div className={`md-progress-circular border-color__${theme}`}></div>
        </div>
      ) : (
        <Leagues />
      )}
    </>
  );
};

export default connect((state, ownProps) => {
  return {
    events: getEvents(state, ownProps),
    leagues: getLeagues(state, ownProps),
  };
}, {})(App);
