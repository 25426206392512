import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import keys from "lodash/keys";
import isUndefined from "lodash/isUndefined";
import { ResizeObserver } from "@juggle/resize-observer";
import { observer, observe } from "redux-observers";
import {
  updateItem,
  replaceItem,
  addMessage,
  updateErrorMessage,
  loadRecommendations,
  unsubscribeBulk,
} from "../actions";
import {
  requestSession,
  requestLeagues,
  requestEvents,
  requestMarketTypes,
  postSize,
  getEvents,
  isMobile,
  navigateToEventsHandler,
  boostingRules,
  NUM_RECOMMENDED_EVENTS,
} from "../utils";

import head from "lodash/first";
import last from "lodash/last";
import take from "lodash/take";
import sortBy from "lodash/sortBy";
import flatten from "lodash/flatten";
import values from "lodash/values";
import groupBy from "lodash/groupBy";
import map from "lodash/fp/map";

window.initApp = function () {
  window.postMessage({ type: "selectedLanguage", value: "eng" });
  window.postMessage({ type: "loginInProgress", value: true });
  window.postMessage({ type: "loginInProgress", value: false });
  window.postMessage({ type: "initialLoad", value: true });
  // window.postMessage({ type: "userId", value: "520535" });
  window.postMessage({ type: "userId", value: "0" });
};

// window.initApp();
const REQUEST_IDS = ["leagues", "events", "live_events"];
const SUCCESS = 0;

export const SITE_ID = process.env.REACT_APP_BC_APP_ID;
//eu
// export const SITE_ID = "521";
//ee
// export const SITE_ID = "56";
//lt
// export const SITE_ID = "313";
//lv
//export const SITE_ID = "656";
//staging
//export const SITE_ID = "112233";

const DEFAULT_USER_ID = "0";
const MILLIS_PER_MINUTE = 60000;

export const initSocketHandlers = (store) => {
  const ws = new WebSocket("wss://eu-swarm-ws-re.betconstruct.com");
  ws.onopen = (event) => {
    console.log("Websocket connection opened.");
    updateItem({
      entityKey: "widget",
      id: "main",
      data: { socketConnectionOpen: true },
    })(store.dispatch, store.getState);
  };

  ws.onclose = (event) => {
    console.log("Websocket connection closed.");
    updateItem({
      entityKey: "widget",
      id: "main",
      data: { socketConnectionOpen: false },
    })(store.dispatch, store.getState);
  };

  // Refresh recommendations every 30 minutes
  setInterval(function () {
    loadRecommendations()(store.dispatch, store.getState);
  }, MILLIS_PER_MINUTE * 5);

  ws.onmessage = (event) => {
    const message = JSON.parse(event.data);
    const { code, rid, data, msg } = message;
    const subid = get(data, "subid");

    if (code !== SUCCESS) {
      updateErrorMessage(msg);
    } else if (REQUEST_IDS.includes(rid) && subid) {
      addMessage({ ...data, rid })(store.dispatch, store.getState);
      updateItem({
        entityKey: "loadingStatus",
        id: rid,
        data: { hasLoaded: true },
      })(store.dispatch, store.getState);
    } else if (rid === "market_type") {
      replaceItem({
        entityKey: "market_type",
        id: "main",
        data: data.details,
      })(store.dispatch, store.getState);
    } else if (rid === "session") {
      updateItem({
        entityKey: "messages",
        id: "sid",
        data: { data: data.sid },
      })(store.dispatch, store.getState);
    } else {
      const msgId = keys(data);
      msgId.forEach((id) => {
        updateItem({
          entityKey: "messages",
          id: id,
          data: { data: get(data, id) },
        })(store.dispatch, store.getState);
      });
    }
  };

  const loadRecommendationsObserver = observer(
    (state) => get(state, "entities.auth.user.id"),
    (dispatch, userId) => {
      loadRecommendations()(store.dispatch, store.getState);
    }
  );

  const socketRequestsObserver = observer(
    (state) => {
      return {
        recommendations: get(
          state,
          "lists.recommendations.current.response.data"
        ),
        sessionId: get(state, "entities.messages.sid.data"),
      };
    },
    (dispatch, { recommendations, sessionId }) => {
      if (recommendations && sessionId) {
        unsubscribeBulk(ws)(store.dispatch, store.getState);

        const maxTb = head(recommendations)?.total_bets;
        const minTb = last(recommendations)?.total_bets;

        let boostedRecommendations = map((rec, idx) => ({
          ...rec,
          confidence: boostingRules(rec, idx, maxTb, minTb),
        }))(recommendations);

        //too many boosted events in /recommended endpoint
        //take up nearly all of NUM_RECOMMENDED_EVENTS slots
        //so have to pic only 7 events per boosted league
        //for maximum league variety
        const groupByLeague = values(
          groupBy(boostedRecommendations, "league_id")
        );
        const reducedLeagues = flatten(groupByLeague.map((l) => take(l, 10)));

        boostedRecommendations = take(
          sortBy(reducedLeagues, (r) => -r.confidence),
          NUM_RECOMMENDED_EVENTS
        );

        const eventIds = recommendations
          ? boostedRecommendations.map((r) => parseInt(r.event_id))
          : [];
        requestEvents(ws, eventIds);

        const leagueIds = uniqBy(boostedRecommendations, "league_id").map((r) =>
          parseInt(r.league_id)
        );
        requestLeagues(ws, leagueIds);
      }
    }
  );

  const languageObserver = observer(
    (state) => {
      return {
        lang: get(state, "entities.language.selected.value"),
        isConnectionOpen: get(
          state,
          "entities.widget.main.socketConnectionOpen"
        ),
      };
    },
    (dispatch, { lang, isConnectionOpen }) => {
      // The BC client sets the language on iframe start. We have no
      // reliable way to get the current user. The userId is only
      // posted to us only for logged in users. Since the language
      // is currently the last message posted to us we can assume that
      // in the logged in cases the user will have been posted to us
      // when the language is posted.
      if (lang && isConnectionOpen) {
        requestSession({
          ws,
          params: {
            language: lang,
            site_id: SITE_ID,
          },
        });
        requestMarketTypes(ws);
      }

      // If external id is undefined after some we set it to a default value.
      setTimeout(() => {
        const externalId = get(
          store.getState(),
          "entities.auth.user.externalId"
        );
        if (lang && isUndefined(externalId)) {
          updateItem({
            entityKey: "auth",
            id: "user",
            data: {
              externalId: DEFAULT_USER_ID,
              id: DEFAULT_USER_ID,
              ab_test_group: "test",
            },
          })(store.dispatch, store.getState);
        }
      }, 700);
    }
  );

  const widgetObserver = observer(
    (state) => get(state, "entities.widget.main"),
    (dispatch, widget, previousWidget) => {
      if (
        widget.isReady ||
        (widget.rect.width !== previousWidget.rect.width &&
          widget.rect.height !== previousWidget.rect.height) ||
        widget.rect.width > 0 ||
        widget.rect.height > 100
      ) {
        postSize(widget.rect);
      } else {
        return;
      }
    }
  );

  const initialLoadObserver = observer(
    (state) => {
      return {
        initialLoad: get(state, "entities.widget.main.initialLoad"),
        user: get(state, "entities.auth.user"),
        recommendations: get(
          state,
          "lists.recommendations.current.response.data"
        ),
        eventsStatus: get(
          state,
          "entities.loadingStatus.events.hasLoaded",
          false
        ),
      };
    },
    (dispatch, { initialLoad, user, eventsStatus, recommendations }) => {
      if (
        initialLoad &&
        recommendations &&
        user &&
        eventsStatus &&
        !isMobile()
      ) {
        const events = getEvents(store.getState());
        navigateToEventsHandler(events, user);
      }
    }
  );

  observe(store, [
    socketRequestsObserver,
    languageObserver,
    loadRecommendationsObserver,
    widgetObserver,
    initialLoadObserver,
  ]);

  const ro = new ResizeObserver((entries, observer) => {
    const width = document.body.clientWidth;
    const height = document.body.clientHeight;

    const widget = get(store.getState(), "entities.widget.main");
    replaceItem({
      entityKey: "widget",
      id: "main",
      data: { ...widget, rect: { width, height } },
    })(store.dispatch, store.getState);
  });

  ro.observe(document.body);

  window.onmessage = (message) => {
    const type = get(message, "data.type");
    if (!type) {
      return;
    }

    console.log("--- vaix widget onmessage ---", type, message.data);

    if (type === "betSlipEventsChange") {
      replaceItem({
        entityKey: "betSlip",
        id: "current",
        data: message.data.value,
      })(store.dispatch, store.getState);
    } else if (type === "userId") {
      const userId = message.data.value || DEFAULT_USER_ID;
      updateItem({
        entityKey: "auth",
        id: "user",
        data: { externalId: userId, id: userId, ab_test_group: "test" },
      })(store.dispatch, store.getState);
    } else if (type === "selectedLanguage") {
      updateItem({
        entityKey: "language",
        id: "selected",
        data: { value: message.data.value },
      })(store.dispatch, store.getState);
    } else if (type === "vaixWidgetReady") {
      updateItem({
        entityKey: "widget",
        id: "main",
        data: { isReady: true },
      })(store.dispatch, store.getState);
    } else if (type === "loginInProgress") {
      if (isUndefined(message.data.value)) {
        // If we receive an undefined value for the login in progress
        // message that means the user is logged out so we set a default
        // user id as the current one.
        updateItem({
          entityKey: "auth",
          id: "user",
          data: {
            externalId: DEFAULT_USER_ID,
            id: DEFAULT_USER_ID,
            ab_test_group: "test",
          },
        })(store.dispatch, store.getState);
      }
    } else if (type === "unsubscribe") {
      unsubscribeBulk(ws)(store.dispatch, store.getState);
    } else if (type === "initialLoad") {
      updateItem({
        entityKey: "widget",
        id: "main",
        data: { initialLoad: message.data.value },
      })(store.dispatch, store.getState);
    }
  };

  if (window.parent) {
    window.parent.postMessage(
      {
        action: "customLeftMenuWidgetMounted",
      },
      "*"
    );
  }
};
