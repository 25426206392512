import * as ActionTypes from "../actions";
import { combineReducers } from "redux";
import updeep from "updeep";
// import get from "lodash/get";

const entities = (
  state = {
    language: { selected: { value: "", socketConnectionOpen: false } },
    widget: {
      main: {
        isReady: false,
        rect: { width: 0, height: 0 },
        initialLoad: false,
      },
    },
  },
  action
) => {
  const { type, payload, meta } = action;
  // const schema = get(meta, 'schema')

  let ret = state;
  if (payload && payload.entities) ret = updeep(payload.entities, ret);

  switch (type) {
    case ActionTypes.UPDATE_ITEM:
      return updeep({ [meta.key]: { [meta.id]: { ...payload } } }, ret);
    case ActionTypes.REPLACE_ITEM:
      return updeep(
        { [meta.key]: { [meta.id]: updeep.constant(payload) } },
        ret
      );
    case ActionTypes.RESET_ITEMS:
      return updeep({ [meta.key]: updeep.constant({}) }, ret);
    case ActionTypes.UNSUBSCRIBE_BULK:
      return updeep(
        { messages: updeep.constant({ sid: ret.messages.sid }) },
        ret
      );
    default:
      return ret;
  }
};

const lists = (state = {}, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case ActionTypes.LIST_REQUEST:
      return updeep(
        {
          [meta.reqId]: {
            [meta.key]: { isFetching: true },
          },
        },
        state
      );
    case ActionTypes.LIST_SUCCESS:
      return updeep(
        {
          [meta.reqId]: {
            [meta.key]: {
              isFetching: false,
              response: payload,
            },
          },
        },
        state
      );
    case ActionTypes.LIST_FAILURE:
      return updeep(
        {
          [meta.reqId]: {
            [meta.key]: { isFetching: false },
          },
        },
        state
      );
    default:
      return state;
  }
};

const errorMessage = (state = null, action) => {
  const { type, error } = action;

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return error;
  }

  return state;
};

export default combineReducers({
  entities,
  lists,
  errorMessage,
});
