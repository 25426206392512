import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  getEvents,
  getEventsByLeague,
  navigateToGame,
  getLeagueFromEvent,
  navigateToLeague,
  placeBet,
  isMobile,
  trackEvent,
  usePrevious,
  getMarketTypesBySport,
} from "../../utils";
import componentSettings from "./../componentsSettings";
import classnames from "classnames";
import { T } from "../../translations";
import "./Events.css";
import "../App/Fonts.css";

import get from "lodash/get";
import map from "lodash/map";
import values from "lodash/values";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import startCase from "lodash/startCase";
import take from "lodash/take";
import isUndefined from "lodash/isUndefined";
import flatten from "lodash/flatten";

const MAX_LEAGUES = 15;
const widget = "landing-widget:recommended-events";

const OutcomeButton = ({ outcome, isSelected, isBlocked, isLive, onClick }) => {
  const { price } = outcome;
  const prevPrice = usePrevious(price);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (price > prevPrice) {
      setStatus("increasing");
    } else if (price < prevPrice) {
      setStatus("decreasing");
    }
  }, [price, prevPrice]);

  return (
    <div className="vbox event__odds__button">
      <div
        className={classnames("odds_factor vbox__spacer vbox vbox--vcenter", {
          "odds_factor--selected": isSelected,
          "odds_factor--blocked": isBlocked,
        })}
        onClick={onClick}
      >
        {isBlocked ? (
          <span className="lock-icon"></span>
        ) : (
          <div
            className={classnames("hbox--reverse hbox--vcenter vbox--vcenter", {
              "odds_factor--increasing":
                isLive && !isBlocked && status === "increasing",
              "odds_factor--decreasing":
                isLive && !isBlocked && status === "decreasing",
            })}
          >
            {price.toFixed(2)}
          </div>
        )}
      </div>
    </div>
  );
};

const Event = ({ game, betSlip, user, isHidden, defaultMarketType }) => {
  const {
    team1_name,
    team2_name,
    start_ts,
    markets_count,
    is_live,
    is_blocked,
    info,
    stats,
    defaultMarket,
  } = game;

  const isLive = is_live === 1;
  const isBlocked = is_blocked === 1;

  const specialBet = get(game, "sport_alias") === "SpecialBets";

  const renderTeamInfo = (name) => (
    <p
      className={classnames("hbox__spacer  ", {
        "text--elipsis ": !specialBet,
        "text--small": specialBet,
      })}
    >
      {name}
    </p>
  );

  const renderTennisScore = (scoreKey) => {
    const teamValue = `team${scoreKey.slice(-1)}_value`;
    const currentSetNum = get(info, "current_game_state", "").slice(-1);
    const scoreSet = get(stats, `score_set${currentSetNum}`, "");

    return isLive ? (
      <div className="score-cols">
        <div className="event__team-score">{get(scoreSet, teamValue)}</div>
        <div className="event__team-score-black">{get(info, scoreKey)}</div>
        <div className="event__team-score-black">
          {get(stats, `passes.${teamValue}`)}
        </div>
      </div>
    ) : (
      <div></div>
    );
  };

  const renderTeamScore = (scoreKey) => {
    const isTennis = get(game, "sport.name") === "Tennis";

    return isTennis ? (
      renderTennisScore(scoreKey)
    ) : (
      <div className="event__team-score">{isLive && get(info, scoreKey)}</div>
    );
  };

  const renderLiveInfo = () => {
    const sport = get(game, "sport.alias", "").toLowerCase();
    const { features } = get(componentSettings, "components.events");
    const specialStatus = {
      notstarted: "Not started yet",
    };

    const sportFeatures = features[sport];
    const state = get(info, "current_game_state");
    const parsedState =
      get(sportFeatures, state) || specialStatus[state] || startCase(state);

    return (
      <div className="event__live-info hbox hbox--space-between">
        <div className="event__live-info__game-state">
          {<span className="live-events-icon"></span>}
          {parsedState}{" "}
          {get(sportFeatures, "showTime") ? get(info, "current_game_time") : ""}{" "}
          {isBlocked && <span className="lock-icon"></span>}
        </div>
      </div>
    );
  };

  const renderMarketsCount = () => {
    const isTennisLive = get(game, "sport.name", "") === "Tennis" && isLive;

    return (
      <div className="event__live-info__markets-count">
        {isTennisLive ? "" : `+${Math.max(markets_count, 0)}`}
        <span className="chevron chevron--right"></span>
      </div>
    );
  };

  const outcomes = sortBy(values(get(defaultMarket, "event", [])), "order");

  const handleOutcomeClick = (game, outcome) => {
    trackEvent({
      event_type: "clicks:outcome",
      user_id: user.id,
      data: {
        isMobile: isMobile(),
        eventId: get(game, "id"),
        outcome: { id: outcome.id },
        slot: game.slot,
        user: user.id,
        widget,
      },
    });
    placeBet(game, outcome);
  };

  const handleGameClick = () => {
    trackEvent({
      event_type: "clicks:event",
      user_id: user.id,
      data: {
        isMobile: isMobile(),
        eventId: get(game, "id"),
        user: user.id,
        slot: game.slot,
        widget,
      },
    });
    navigateToGame(game);
  };

  return (
    <li
      className={classnames("event-list__item event-list-item--large", {
        hide: isHidden,
      })}
    >
      <div className="event-list-item__content hbox hbox--space-between">
        <div
          className="hbox__min-width hbox hbox__spacer hbox--space-between"
          onClick={handleGameClick}
        >
          <div className="event__title vbox">
            {renderTeamInfo(team1_name)}
            {team2_name && renderTeamInfo(team2_name)}
            {!isLive ? (
              <div className="event__timestamp text--elipsis">
                {moment.unix(start_ts).format("dd, DD MMM YYYY HH:mm")}
              </div>
            ) : (
              renderLiveInfo()
            )}
          </div>
          {!specialBet ? (
            <div className="vbox__padding-r vbox hbox--space-between hbox--vbottom">
              <div className="score-rows">
                {renderTeamScore("score1")}
                {team2_name && renderTeamScore("score2")}
              </div>
              <div className="market-num">{renderMarketsCount()}</div>
            </div>
          ) : null}
        </div>
        <div className={classnames("hbox hbox--hend", {})}>
          {!isEmpty(outcomes) &&
            map(outcomes, (outcome) => (
              <OutcomeButton
                key={outcome.id}
                outcome={outcome}
                isSelected={betSlip[outcome.id]}
                isBlocked={isBlocked}
                isLive={isLive}
                onClick={(e) => handleOutcomeClick(game, outcome)}
              />
            ))}
        </div>
      </div>
    </li>
  );
};

const EventListHeader = ({
  title,
  marketType,
  flagClass,
  iconClass,
  cssClass,
  backgroundClass,
  isHidden,
  onClick,
}) => {
  return (
    <div
      className={classnames("hbox hbox--vcenter", {
        [cssClass]: cssClass,
      })}
      onClick={onClick}
    >
      {iconClass && (
        <span
          className={classnames("events-list__header__icon", {
            [iconClass]: iconClass,
          })}
        ></span>
      )}
      {flagClass && (
        <span
          className={classnames("", {
            [flagClass]: flagClass,
          })}
        ></span>
      )}
      <div className="hbox__spacer text--elipsis">
        {!backgroundClass && title}
      </div>
      {isHidden ? (
        <div className="collapse-arrow hbox-item--flex-end"></div>
      ) : (
        marketType && (
          <div
            className={classnames("event__odds hbox hbox--hend hbox__spacer")}
          >
            {marketType.SelectionTypesNames.map((selName) => (
              <div
                className={classnames("event__outcome", { hide: isHidden })}
                key={selName}
              >
                {selName}
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

const EventListFooter = ({ onClick, language, title }) => {
  return (
    <div onClick={onClick}>
      <div className="go-to-league-button">
        <div>
          {T(language, "go_to_all")} <b>{title}</b>
        </div>
        <div className="go-to-league-button-arrow"></div>
      </div>
    </div>
  );
};

const EventsList = ({
  events,
  betSlip,
  user,
  language,
  isCollapsed,
  marketTypes,
  isCollapsible = true,
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed && isCollapsible);
  const groupKey = "competition.name";

  const refEvent = events[0];

  //does not contain specialbets / olyboosts
  const defaultMarketType = get(marketTypes, [refEvent.sport.alias, 0]);

  const getEventMarket = (event) => {
    const singleMarket = values(get(event, "market"));
    let display_key = get(singleMarket[0], "display_key");
    return get(event, "markets_count") === 1 && display_key === "OUTRIGHT"
      ? singleMarket[0]
      : find(
          values(get(event, "market", {})),
          (m) => get(m, "type") === get(defaultMarketType, "BasaltKind")
        );
  };

  const filteredEvents = events
    .map((e) => ({
      ...e,
      defaultMarket: getEventMarket(e),
    }))
    .filter((e) => !isUndefined(e.defaultMarket));

  if (filteredEvents.length === 0) {
    return <ul></ul>;
    //return null;
  }

  const handleCollapse = () => {
    const league = getLeagueFromEvent(refEvent);
    trackEvent({
      event_type: "clicks:menu-item",
      user_id: user.id,
      data: {
        menu: "recommended-leagues",
        isMobile: isMobile(),
        isCollapsed: isCollapsible && !collapsed,
        item: league.id,
        user: user.id,
        widget,
      },
    });
    setCollapsed(!collapsed && isCollapsible);
  };

  const leagueClickHandler = () => {
    const league = getLeagueFromEvent(refEvent);
    trackEvent({
      event_type: "clicks:league",
      user_id: user.id,
      data: {
        isMobile: isMobile(),
        item: league.id,
        widget,
        user: user.id,
      },
    });
    navigateToLeague(league);
  };

  const title = T(language, get(refEvent, groupKey));

  const getLeagueStyles = () => {
    const leagueId = get(refEvent, "competition.id");
    const { styles } = get(componentSettings, "components.events");
    const fixedStyles = get(styles, leagueId);
    return {
      iconClass: `sport-icon ${get(refEvent, "sport.alias", "")}`,
      flagClass: `flag-icon flag-${get(refEvent, "region.alias", "")
        .toLowerCase()
        .replaceAll(" ", "")}`,
      backgroundClass: null,
      ...fixedStyles,
    };
  };

  const { iconClass, flagClass, backgroundClass } = getLeagueStyles();

  return (
    <ul className="events-list">
      <li className="event-list__item">
        <div
          className={classnames("list__title--background", {
            [`${backgroundClass}-background`]: backgroundClass,
          })}
        >
          <div
            className={classnames("event-list__title vbox vbox--vcenter", {
              [backgroundClass]: backgroundClass,
            })}
          >
            <EventListHeader
              isHidden={collapsed}
              title={title}
              iconClass={iconClass}
              flagClass={flagClass}
              backgroundClass={backgroundClass}
              marketType={defaultMarketType}
              onClick={handleCollapse}
            />
          </div>
        </div>
      </li>
      {filteredEvents.map((event) => {
        return (
          <Event
            key={event.id}
            game={event}
            betSlip={betSlip}
            user={user}
            isHidden={collapsed}
            defaultMarketType={defaultMarketType}
          />
        );
      })}
      {!collapsed && (
        <EventListFooter
          onClick={leagueClickHandler}
          language={language}
          title={title}
        ></EventListFooter>
      )}
    </ul>
  );
};

const EventsContainer = (props) => {
  let groupKey = "competition.id";
  let leagueEvents = getEventsByLeague(props.events, groupKey);
  let marketTypes = getMarketTypesBySport(props.marketTypes);
  const [trackedEvents, setTrackedEvents] = useState(false);

  useEffect(() => {
    if (!trackedEvents && leagueEvents && leagueEvents.length > 0) {
      setTrackedEvents(true);
      trackEvent({
        event_type: "impressions:events",
        user_id: props.user.id,
        data: {
          isMobile: isMobile(),
          events: flatten(
            take(leagueEvents, MAX_LEAGUES).map((events) =>
              take(events, 5).map((event) => ({
                id: event.id,
              }))
            )
          ),
          widget,
          user: props.user.id,
        },
      });
    }
  }, [leagueEvents, trackedEvents, props.user]);

  const { isCollapsible, defaultOpenLeagues } = get(
    componentSettings,
    "components.events"
  );

  const leagueIds = take(leagueEvents, MAX_LEAGUES).map((events) =>
    get(events, "0.competition.id")
  );

  const isLeagueCollapsible = leagueIds.map((id) =>
    get(isCollapsible, id, get(isCollapsible, "default"))
  );

  return take(leagueEvents, MAX_LEAGUES).map((events, index) => (
    <EventsList
      {...props}
      marketTypes={marketTypes}
      key={events[0].competition.id}
      events={events}
      isCollapsed={index > defaultOpenLeagues}
      isCollapsible={isLeagueCollapsible[index]}
    />
  ));
};

export default connect((state, ownProps) => {
  return {
    events: getEvents(state, ownProps),
    betSlip: get(state, "entities.betSlip.current", {}),
    user: get(state, "entities.auth.user", { id: 0, ab_test_group: "control" }),
    language: get(state, "entities.language.selected.value"),
    marketTypes: get(state, "entities.market_type.main"),
  };
}, {})(EventsContainer);
