import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import List from "../List";
import {
  getLeagues,
  getEvents,
  navigateToLeague,
  navigateToEventsHandler,
  isMobile,
  trackEvent,
} from "../../utils";
import { T } from "../../translations";
import get from "lodash/get";
import map from "lodash/map";
import componentsSettings from "../../components/componentsSettings";

const widget = "landing-widget:recommended-leagues";

const theme = componentsSettings.components.leagues.enabledTheme;
import(`./${theme}Leagues.css`);
const coloredFonts = componentsSettings.components.leagues.coloredFonts;
coloredFonts ? import(`../App/coloredFonts.css`) : import("../App/Fonts.css");

const League = ({ league, user }) => {
  const { game, name } = league;

  const leagueClickHandler = () => {
    trackEvent({
      event_type: "clicks:league",
      user_id: user.id,
      data: {
        isMobile: isMobile(),
        item: league.id,
        user: user.id,
        widget,
      },
    });
    navigateToLeague(league);
  };

  return (
    <li
      className={`list-item__${theme} list-item--medium__${theme}`}
      onClick={leagueClickHandler}
    >
      <div className={`list-item__content--${theme} hbox hbox--vcenter`}>
        <span
          className={classnames(
            coloredFonts
              ? `sport-icon--d c-${get(league, "sport.alias")}`
              : `sport-icon ${get(league, "sport.alias")}`
          )}
        ></span>
        <span
          className={`text--elipsis hbox__spacer list-item__title--${theme}`}
        >
          {name}
        </span>
        <span className={`hpad--8 list-item__counter--${theme}`}>
          {Object.keys(game).length}
        </span>
        <span
          className={`flag-icon__${theme} flag-${get(
            league,
            "region.alias"
          ).toLowerCase()}`}
        ></span>
      </div>
    </li>
  );
};

const LeaguesList = ({ leagues, events, user, language }) => {
  const [trackedLeagues, setTrackedLeagues] = useState(false);

  useEffect(() => {
    if (!trackedLeagues && leagues && leagues.length > 0) {
      setTrackedLeagues(true);
      trackEvent({
        event_type: "impressions:leagues",
        user_id: user.id,
        data: {
          isMobile: isMobile(),
          leagues: leagues.map((league) => ({ id: league.id })),
          user: user.id,
          widget,
        },
      });
    }
  }, [events, trackedLeagues, leagues, user]);

  const listItems = map(leagues, (item) => (
    <League key={item.id} league={item} user={user} />
  ));

  return (
    <React.Fragment>
      {!isMobile() && (
        <div
          className={`hbox hbox--vcenter list-button__${theme}`}
          onClick={(e) => navigateToEventsHandler(events, user)}
        >
          <span className={`fav-star-m highlighted__${theme}`}></span>
          <span>{T(language, "recommended_competitions")}</span>
        </div>
      )}

      <List cssTheme={`${theme}`} listItems={listItems} />
    </React.Fragment>
  );
};

export default connect((state, ownProps) => {
  return {
    leagues: getLeagues(state, ownProps),
    events: getEvents(state, ownProps),
    user: get(state, "entities.auth.user", { id: 0, ab_test_group: "control" }),
    language: get(state, "entities.language.selected.value"),
  };
}, {})(LeaguesList);
