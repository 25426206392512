import React from "react";
import "./Loader.css";

const Title = () => {
  return (
    <ul className="sport-title">
      <li className="icon skeleton-loader"></li>
      <li className="text skeleton-loader"></li>
    </ul>
  );
};

const Items = () => {
  return (
    <ul className="item">
      <li className="game-name">
        <div className="name team-1 skeleton-loader"></div>
        <div className="name team-2 skeleton-loader"></div>
        <div className="time skeleton-loader"></div>
      </li>
      <li className="odd-block">
        <div className="odd skeleton-loader"></div>
      </li>
      <li className="odd-block">
        <div className="odd skeleton-loader"></div>
      </li>
      <li className="odd-block">
        <div className="odd skeleton-loader"></div>
      </li>
    </ul>
  );
};

const Skeleton = ({ itemNum }) => {
  const reps = [...Array(itemNum).keys()];
  return (
    <>
      <Title />
      {reps.map((rep) => (
        <Items key={rep} />
      ))}
    </>
  );
};

const AnimationLoader = () => {
  return (
    <div className="gamelist-skeleton bg">
      <Skeleton itemNum={4} />
      <Skeleton itemNum={2} />
      <Skeleton itemNum={3} />
      <Skeleton itemNum={6} />
    </div>
  );
};

export default AnimationLoader;
