import React from "react";
import componentSettings from "../componentsSettings";
import classnames from "classnames";
//import "./List.css";
//import "./darkList.css";

const theme = componentSettings.components.list.enabledTheme;
import(`./${theme}List.css`);

const List = ({ listItems, cssTheme }) => {
  return (
    <ul id={`${cssTheme}-theme`}>
      <div
        className={classnames(cssTheme ? `list-items__${theme}` : "list-items")}
      >
        {listItems}
      </div>
    </ul>
  );
};

export default List;
