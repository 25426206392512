import { useEffect, useRef } from "react";
import get from "lodash/get";
import flatten from "lodash/flatten";
// import head from "lodash/first";
// import last from "lodash/last";
import map from "lodash/fp/map";
import values from "lodash/values";
import take from "lodash/fp/take";
import keyBy from "lodash/fp/keyBy";
import groupBy from "lodash/fp/groupBy";
import sortBy from "lodash/fp/sortBy";
import find from "lodash/find";
import uniq from "lodash/fp/uniq";
import uniqBy from "lodash/fp/uniqBy";
import flow from "lodash/fp/flow";
import compact from "lodash/fp/compact";
import filter from "lodash/fp/filter";
import mapValues from "lodash/fp/mapValues";
import maxBy from "lodash/fp/maxBy";

import { schema } from "normalizr";
import { createSelector } from "reselect";
import settings from "./settings";
import componentSettings from "./components/componentsSettings";

// This is in order to pass the extra index parameter
// in functional map. Lodash functional caps parameters
// by default to 1.
const uncappedMap = map.convert({ cap: false });

export const isMobile = () => {
  // found at https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  // mobile+tablet version
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const trackEvent = function (data) {
  fetch(settings.BASE_API_URL + "api/tracker/events", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${settings.API_TOKEN}`,
    },
    body: JSON.stringify(data),
  });
};

export function bindKeywordArguments(fn, args) {
  return function (nextArgs) {
    return fn({ ...nextArgs, ...args });
  };
}

export const sendMsgToWS = ({ ws, ...msg }) => {
  ws.send(JSON.stringify(msg));
};

export const requestSession = ({ ws, params }) => {
  return sendMsgToWS({
    ws,
    command: "request_session",
    rid: "session",
    params: params,
  });
};

export const removeSession = bindKeywordArguments(sendMsgToWS, {
  command: "remove_session",
});

export const requestData = ({ ws, rid, params, conditions }) => {
  return sendMsgToWS({
    ws,
    rid,
    command: "get",
    params: {
      source: "betting",
      subscribe: true,
      what: params,
      where: conditions,
    },
  });
};

export const unsubscribeBulk = ({ ws, subids }) => {
  return sendMsgToWS({
    ws,
    command: "unsubscribe_bulk",
    params: { subids },
  });
};

export const requestLeagues = (ws, ids) => {
  return requestData({
    ws,
    rid: "leagues",
    params: {
      sport: ["id", "name", "alias"],
      region: ["id", "name", "alias"],
      competition: ["id", "name", "order"],
      game: ["id", "name", "@count"],
    },
    conditions: {
      competition: {
        id: { "@in": ids },
      },
    },
  });
};

export const requestEvents = (ws, ids) => {
  return requestData({
    ws,
    rid: "events",
    params: {
      sport: ["id", "name", "alias", "order"],
      competition: ["id", "order", "name"],
      region: ["id", "name", "alias"],
      game: [
        [
          "id",
          "team1_name",
          "team2_name",
          "team1_id",
          "team2_id",
          "order",
          "start_ts",
          "is_live",
          "info",
          "stats",
          "markets_count",
          "is_blocked",
          "exclude_ids",
          "team1_reg_name",
          "team2_reg_name",
          "type",
          "game_number",
          "game_info",
          "sport_alias",
          "region_alias",
          "_parent_id",
        ],
      ],
      market: [
        "cashout",
        "col_count",
        "display_color",
        "display_key",
        "group_id",
        "group_name",
        "group_order",
        "id",
        "market_type",
        "name",
        "name_template",
        "optimal",
        "order",
        "point_sequence",
        "sequence",
        "type",
        "is_new",
        "home_score",
        "away_score",
        "sport_alias",
        "region_alias",
        "_parent_id",
        "express_id",
      ],
      event: [],
    },
    conditions: {
      game: {
        id: { "@in": ids },
      },
      market: {
        "@or": [
          { display_key: { "@in": ["OUTRIGHT", "WINNER"] } },
          {
            type: {
              "@in": ["P1XP2", "P1P2", "MatchWinner", "MatchResult", "Winner"],
            },
          },
        ],
      },
    },
  });
};

export const requestMarketTypes = (ws) => {
  return sendMsgToWS({
    ws,
    rid: "market_type",
    command: "get_market_type",
    params: {},
  });
};

export const unsubscribe = (ws, subscriptionIds) => {
  return requestData({
    ws,
    command: "unsubscribe_bulk",
    params: {
      subids: subscriptionIds,
    },
  });
};

function dfWalk(parent, paths, nodes, parents) {
  if (!parent || paths.length === 0) {
    return;
  }
  if (paths.length === 1 && parent[paths[0]]) {
    values(parent[paths[0]]).forEach((n) => {
      nodes.push({ ...n, ...parents });
    });
  }
  const data = values(parent[paths[0]]);
  const newPaths = paths.slice(1);

  data.forEach((node) => {
    const parentData = { ...parents };
    parentData[paths[0]] = node;
    dfWalk(node, newPaths, nodes, parentData);
  });
}

export const formatLeagues = (data) => {
  const nodes = [];
  dfWalk(data, ["sport", "region", "competition"], nodes, {});
  return nodes;
};

export const formatEvents = (data) => {
  const nodes = [];
  dfWalk(data, ["sport", "region", "competition", "game"], nodes, {});
  return nodes;
};

export function getItemSchema(state, entityKey) {
  return new schema.Entity(
    entityKey,
    {},
    {
      idAttribute: (entity) => {
        const idGetter = get(state, `ui.entities[${entityKey}].getEntityId`);
        return idGetter({ state, entity });
      },
    }
  );
}

const leaguesSelector = (state, ownProps) =>
  get(
    find(state.entities.messages || {}, (el) => el.rid === "leagues"),
    "data"
  );

const eventsSelector = (state, ownProps) =>
  get(
    find(state.entities.messages || {}, (el) => el.rid === "events"),
    "data"
  );

/**
 * Normalizes total_bets to be in the range of [0,1]
 *
 * @param {array} rec - Recommendations
 * @param {number} max - maximum value of total_bets
 * @param {number} min - minimum value of total_bets
 *
 * @return {number} confidence or normalized betcount
 * depending on the endpoint used
 */
const calculateConfidence = (rec, max, min) => {
  const normalizeBetcount = (val, max, min) => (val - min) / (max - min);

  if (rec?.confidence === 0) return 0;
  return rec?.confidence || normalizeBetcount(rec.total_bets, max, min);
};

/**
 * Custom ruleset for boosting and deboosting recommendations
 * by modifying their confidence
 *
 * @param {array} rec - Recommendations
 * @param {...number} bounds - min max values passed down to calculateConfidence
 *
 * @return {number} value of confidence
 */
export const boostingRules = (rec, ...bounds) => {
  const { leaguesToBoost } = componentSettings.components;

  if (rec.league.includes("Outright")) {
    return 0;
  }
  if (leaguesToBoost.indexOf(rec.league_id) !== -1) {
    return 5;
  } else {
    return calculateConfidence(rec, ...bounds);
  }
};

/**
 * Selects recommendations from state and
 * modifies their confidence according
 * to previously defined boosting rules
 *
 * Assumes that recommendations are sorted based on total_bets field.
 * This happens by default when fetching from the '/popular' endpoint
 * in the current API implementation
 */
const recommendationsSelector = (state, props) => {
  const recommendations = get(
    state,
    "lists.recommendations.current.response.data"
  );

  return recommendations;

  // const maxTb = head(recommendations)?.total_bets;
  // const minTb = last(recommendations)?.total_bets;

  // return map((rec) => ({
  //   ...rec,
  //   confidence: boostingRules(rec, maxTb, minTb),
  // }))(recommendations);
};

const NUM_RECOMMENDED_LEAGUES = 8;

export const getLeagues = createSelector(
  [leaguesSelector, recommendationsSelector],
  (leaguesTree, recommendations) => {
    if (!leaguesTree || !recommendations) {
      return [];
    }

    const leagues = formatLeagues(leaguesTree);
    const leaguesById = keyBy("id")(leagues);

    return flow(
      uniqBy("league_id"),
      uncappedMap((l, slot) =>
        leaguesById[l.league_id]
          ? {
              ...leaguesById[l.league_id],
              confidence: l.confidence,
              slot,
            }
          : null
      ),
      compact,
      sortBy((r) => -r.confidence),
      filter((r) => Object.keys(r.game).length > 0),
      take(NUM_RECOMMENDED_LEAGUES),
      groupBy("sport.id"),
      values,
      sortBy((l) => -l[0].confidence),
      flatten
    )(recommendations);
  }
);

const NUM_RECOMMENDED_EVENTS_LIVE = 15;
//const NUM_RECOMMENDED_EVENTS = 80;
export const NUM_RECOMMENDED_EVENTS = 60;

export const getEvents = createSelector(
  [eventsSelector, recommendationsSelector],
  (eventsTree, recommendations) => {
    if (!recommendations || !eventsTree) {
      return [];
    }

    const events = formatEvents(eventsTree);
    const eventsById = keyBy("id")(events);

    return flow(
      uncappedMap((r, slot) =>
        eventsById[r.event_id]
          ? {
              ...eventsById[r.event_id],
              confidence: r.confidence,
              slot,
            }
          : null
      ),
      compact
      // take(NUM_RECOMMENDED_EVENTS),
      // groupBy('competition.name'),
      // values,
      // map(competitionRecs => sortBy('start_ts')(competitionRecs))
    )(recommendations);
  }
);

export const getGroupedEvents = (events, groupKey, isLive = false) => {
  return flow(
    filter((e) => (isLive ? e.is_live === 1 : e.is_live === 0)),
    take(isLive ? NUM_RECOMMENDED_EVENTS_LIVE : NUM_RECOMMENDED_EVENTS),
    groupBy(groupKey),
    values,
    map((groupRecs) => sortBy("start_ts")(groupRecs))
  )(events);
};

export const getEventsByLeague = (events, groupKey) => {
  const getMaxEvents = (events) => {
    const leagueId = get(events[0], "competition.id");

    const { maxLeagueEvents } = get(componentSettings, "components.events");

    return get(maxLeagueEvents, leagueId, get(maxLeagueEvents, "default"));
  };

  return flow(
    groupBy(groupKey),
    values,
    sortBy(
      (groupEvents) => -maxBy((e) => e.confidence)(groupEvents).confidence
    ),
    take(NUM_RECOMMENDED_EVENTS),
    map((groupEvents) => take(getMaxEvents(groupEvents))(groupEvents)),
    map((groupEvents) => sortBy("start_ts")(groupEvents))
  )(events);
};

export const getMarketTypesBySport = (marketTypes) => {
  const ret = flow(
    groupBy("SportAlias"),
    mapValues((sm) => sortBy("Order")(sm))
  )(marketTypes);

  return ret;
};

export const getLeagueFromEvent = (event) => {
  return {
    sport: event.sport,
    id: event.competition.id,
    region: event.region,
  };
};

export const navigateToLeague = (league) => {
  if (window.parent) {
    const data = {
      type: 0,
      sport: parseInt(league.sport.id),
      region: parseInt(league.region.id),
      competition: parseInt(league.id),
      sportAlias: league.sport.alias,
      regionAlias: league.region.alias,
    };

    window.parent.postMessage(
      {
        action: "setLocation",
        deepLink: data,
        reset: true,
      },
      "*"
    );
  }
};

export const navigateToGame = (game) => {
  if (window.parent) {
    const data = {
      type: 0,
      game: parseInt(game.id),
      sport: parseInt(game.sport.id),
      region: parseInt(game.region.id),
      competition: parseInt(game.competition.id),
      sportAlias: game.sport.alias,
      regionAlias: game.region.alias,
    };

    window.parent.postMessage(
      {
        action: "setLocation",
        deepLink: data,
        reset: true,
      },
      "*"
    );
  }
};

const toColumns = (items, columns) => {
  const ret = {};
  columns.forEach((col) => (ret[col.name] = []));

  items.forEach((item) => {
    columns.forEach((col) => {
      const transform = col.transform || parseInt;
      ret[col.name].push(transform(get(item, col.key)));
    });
  });

  columns.forEach((col) => {
    ret[col.name] = uniq(ret[col.name]);
  });

  return ret;
};

export const navigateToEvents = (eventGroups) => {
  if (!window.parent) {
    return;
  }
  let events = flatten(eventGroups);
  events = sortBy((e) => -e["confidence"])(events);

  const columns = [
    { name: "sports", key: "sport.id" },
    { name: "regions", key: "region.id" },
    { name: "competitions", key: "competition.id" },
    { name: "games", key: "id" },
    { name: "sportAliases", key: "sport.alias", transform: (item) => item },
    { name: "regionAliases", key: "region.alias", transform: (item) => item },
  ];

  const data = toColumns(events, columns);

  window.parent.postMessage(
    {
      action: "setLocation",
      deepLink: {
        type: 0,
        sport: -15,
        recommended: encodeURIComponent(JSON.stringify(data)),
      },
      reset: true,
    },
    "*"
  );
};

export const navigateToEventsHandler = (events, user) => {
  trackEvent({
    event_type: "impressions:events",
    user_id: user.id,
    data: {
      isMobile: isMobile(),
      events: flatten(events).map((e) => ({
        id: e.id,
      })),
      user: user.id,
      widget: "landing-widget:recommended-leagues",
    },
  });
  navigateToEvents(events);
};

export const placeBet = (event, outcome) => {
  if (window.parent) {
    const data = {
      action: "vaixDoBet",
      bet: {
        event: outcome,
        market: event.defaultMarket,
        game: event,
        oddType: outcome.type,
      },
    };
    window.parent.postMessage(data, "*");
  }
};

export const postSize = (rect) => {
  if (!window.parent) {
    return;
  }

  window.parent.postMessage(
    {
      action: "vaixSetSize",
      rect,
    },
    "*"
  );
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
