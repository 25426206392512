const componentSettings = {
  components: {
    leaguesToBoost: ["18265893", "18281299"],
    events: {
      defaultOpenLeagues: 5,
      maxLeagueEvents: {
        default: 10,
        18265893: 10,
        18281299: 10,
      },
      isCollapsible: {
        default: true,
        18265893: false,
        18281299: false,
      },
      styles: {
        18265893: {
          iconClass: null,
          flagClass: null,
          backgroundClass: "OlyBoosts",
        },
      },
      features: {
        soccer: {
          set1: "1st Half",
          set2: "2nd Half",
          showTime: true,
        },
        basketball: {
          set1: "Quarter 1",
          set2: "Quarter 2",
          set3: "Quarter 3",
          set4: "Quarter 4",
          showTime: true,
        },
        icehockey: {
          set1: "Period 1",
          set2: "Period 2",
          set3: "Period 3",
          showTime: true,
        },
      },
    },
    leagues: {
      enabledTheme: "dark",
      coloredFonts: true,
    },
    list: {
      enabledTheme: "dark",
      themes: {
        dark: {
          backgroundColor: "rgba(0,0,0)",
        },
      },
    },
    loader: {
      enabledTheme: "dark",
      themes: {
        dark: {
          backgroundColor: "rgba(0,0,0)",
        },
      },
    },
  },
};

export default componentSettings;
